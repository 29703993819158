<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {};
  },
  onLoad() { },
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>